// src/pages/Home.js
import React from 'react';
import Navigation from '../components/Navigation';
import Blob from '../components/Blob';
import Footer from '../components/Footer';
import espressoMachine from '../assets/images/vibe-espresso-machine.jpg';
import latteArt from '../assets/images/placeholders/latte-art.png';
import bltaNoBg from '../assets/images/blta-no-bg.png';
import cinnamonRoll from '../assets/images/cinammon-roll-no-bg.png';
import '../styles/home.css';

const Home = () => {
  return (
    <div id="app">
      <Navigation />
      <section className="hero">
        <div className="hero__content">
          <Blob color="#bac98640" shape="1" alt="Blob Shape 1" top="-85px" left="-85px" animated={true} />
          <Blob color="#F9C3B540" shape="2" alt="Blob Shape 2" left="0px" bottom="-30px" animated={true} />
          <Blob color="#c7dce240" shape="3" alt="Blob Shape 3" top="10%" right="-36%" animated={true} />
          <h1 className="hero__title">
            <div className="hero__title-1">Welcome to</div>
            <div className="hero__title-2">Vessel</div>
            <div className="hero__title-3">Coffee Collective</div>
          </h1>
          <p className="hero__text">
            We are a specialty coffee shop nestled in the Fishtown neighborhood of Philadelphia with locally roasted
            coffee, fresh pastries, and an array of bright food options including gluten-free, vegan, and vegetarian.
          </p>
          <a href="https://order.vesselcoffeeco.com/s/order" target="_blank" rel="noreferrer" className="cta cta--primary">
            Start an Order
          </a>
        </div>
        <div className="hero__media">
          <img src={espressoMachine} alt="Vessel Coffee Collective" />
        </div>
      </section>
      <section className="collectives">
        <div className="collectives--inner">
          <h2>We long to bring the community together.</h2>
          <p>
            We believe coffee shops should be places of community. That's why we strive to bring people from all walks of
            life together, which is how <i>collectives</i> were born. <i>Collectives</i> are interest-based groups,
            that get together regularly to do things they enjoy with their community.
            Whether it's sidewalk chalk, biking, board games or anything in-between, we are confident you will find
            something you enjoy.
          </p>
          <p>We will be making announcements on Instagram when collectives are starting. Follow us to ensure you don't
            miss out!</p>
          <a target="_blank" rel="noreferrer" href="https://www.instagram.com/vesselcoffeecollective/" className="cta cta--secondary">Follow us
            on Instagram</a>
        </div>
        <Blob color="#ffffff40" shape="1" alt="Blob Shape 4" left="0" bottom="-30px" animated={true} />
        <Blob color="#ffffff40" shape="2" alt="Blob Shape 1" top="10%" right="70%" animated={true} />
        <Blob color="#ffffff40" shape="3" alt="Blob Shape 2" bottom="30%" right="10%" animated={true} />
        <div className="shape-divider shape-divider--bottom" style={{ bottom: '-98px' }}>
          <div className="custom-shape-divider">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path fill="#c7dce2"
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"></path>
            </svg>
          </div>
        </div>
      </section>
      <section className="menu-section">
        <div className="menu__wrapper">
          <div className="menu__content">
            <h2>Our Menu</h2>
            <p>
              We are proud to offer a wide variety of food and drink
              options. We have everything from coffee and tea to
              sandwiches and salads. We also have a variety of
              gluten-free, vegan, and vegetarian options.
            </p>
            <a href="/menu" className="cta cta--secondary">View Whole Menu</a>
          </div>
          <div className="menu__media">
            <a className="blob-link drinks" href="/menu#drinks">
              <Blob color="#c7dce2" shape="1" alt="Blob Shape 3" top="-40%" left="-35%" animated={false} />
              <img className="blob-link__image" src={latteArt} alt="Vessel Coffee Collective" />
              <p className="blob-link__text">Coffee + Tea</p>
            </a>
            <a className="blob-link food" href="/menu#food">
              <Blob color="#F9C3B5" shape="2" alt="Blob Shape 3" top="-40%" left="-40%" animated={false} />
              <img className="blob-link__image" src={bltaNoBg} alt="Vessel Coffee Collective" />
              <p className="blob-link__text">Food</p>
            </a>
            <a className="blob-link pastries" href="/menu#pastries">
              <Blob color="#bac98640" shape="3" alt="Blob Shape 3" top="-50%" left="-30%" animated={false} />
              <img className="blob-link__image" src={cinnamonRoll} alt="Vessel Coffee Collective" />
              <p className="blob-link__text">Pastries</p>
            </a>
          </div>
        </div>
      </section>
      <section className="location">
        <div className="shape-divider shape-divider--top" style={{ top: '-98px' }}>
          <div className="custom-shape-divider shape--flipX">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path fill="#F9C3B5"
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"></path>
            </svg>
          </div>
        </div>
        <div className="location--inner">
          <h2>Your Go-To Coffee Spot <br /><span style={{ fontSize: 'var(--fontLarge)' }}>on Frankford Avenue,
            Fishtown | East Kensington</span></h2>
          <div className="location__graphic">
            <iframe
              title="Vessel Coffee Collective Location Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3057.168277182992!2d-75.13037436028173!3d39.98234462553367!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c6c94c1a0a3a75%3A0xbf40007fbecdcf61!2sVessel%20Coffee%20Collective!5e0!3m2!1sen!2sus!4v1717629251451!5m2!1sen!2sus"
              width="400" height="400" style={{ border: '0' }} allowFullScreen="" loading="lazy"></iframe>
          </div>
          <div className="location__content">
            <p>Located on Frankford Avenue, right on the roundabout between Fishtown and East Kensington, our coffee shop
              offers a down-to-earth, quality coffee experience in the heart
              of Philadelphia's most up-and-coming neighborhood. It's the perfect place for locals and visitors
              looking for a great cup of coffee or a bite to eat.</p>

            <a href="https://www.google.com/maps/place/Vessel+Coffee+Collective/@39.9816343,-75.1321396,17z/data=!3m1!4b1!4m6!3m5!1s0x89c6c94c1a0a3a75:0xbf40007fbecdcf61!8m2!3d39.9816303!4d-75.1272687!16s%2Fg%2F11vk92nphb?hl=en&entry=ttu"
              target="_blank" rel="noreferrer" className="cta cta--primary">
              View Directions
            </a>
          </div>
        </div>
        <Blob color="#ffffff40" shape="4" alt="Blob Shape 3" left="10%" top="-30px" animated={true} />
        <Blob color="#ffffff40" shape="3" alt="Blob Shape 1" top="20%" right="50%" animated={true} />
        <Blob color="#ffffff40" shape="2" alt="Blob Shape 2" bottom="20%" right="15%" animated={true} />
        <div className="shape-divider shape-divider--bottom" style={{ bottom: '-98px' }}>
          <div className="custom-shape-divider">
            <svg data-name="Layer 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1200 120" preserveAspectRatio="none">
              <path fill="#F9C3B5"
                d="M321.39,56.44c58-10.79,114.16-30.13,172-41.86,82.39-16.72,168.19-17.73,250.45-.39C823.78,31,906.67,72,985.66,92.83c70.05,18.48,146.53,26.09,214.34,3V0H0V27.35A600.21,600.21,0,0,0,321.39,56.44Z"
                className="shape-fill"></path>
            </svg>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Home;
